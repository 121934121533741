@import 'src/styles/variabels';

.form-tabs {
  .MuiTabs-root {
    .MuiTab-root {
      padding: 1rem 1.6rem;
    }
  }
  .form-with-tabs__language-filter {
    .MuiFormControl-root {
      margin-bottom: 0;
    }
  }
}

.form-edit-wrapper {
  width: 100%;
  height: 100%;
  .form-edit-head {
    .title {
      font-weight: bold;
    }
    .form-edit-controls {
      padding: 0;
      white-space: nowrap;
    }
  }
  .form-edit-content{
    flex: 1 1;
  }
  .form-edit-footer{
    display: flex;
    margin-top: .5rem;
    justify-content: flex-end;
    margin: 0 -.5rem;
    > * {
      margin: 0 .5rem;
    }
  }
}
.form-edit-wrapper {
  padding: 1.6rem;
  .form-edit-head {
    padding-bottom: 1rem;
  }
  .form-edit-content{
    padding: 1rem;
    &_new{
      display: grid;
      grid-template-columns: 32rem 1fr;
      padding: 0;
      .form-edit-content{
        &__content,
        &__main{
          padding: 2rem;
        }
        &__main{
          background: linear-gradient(0deg, #F8F9FA, #F8F9FA), #FFFFFF;
          border: 1px solid #EAEAEA;
        }
      }
    }
  }
}

.scroll-content{
  overflow: hidden;
}
.form-edit-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .MuiButton-root {
    height: 3.6rem;
    min-width: 10rem;
    &.btn-delete {
      background-color: transparent;
      box-shadow: none;
      font-size: 0;
      min-width: inherit;
      svg {
        font-size: 2rem;
        color: rgb(220, 0, 78);
      }
    }
  }
}
//content-object-form
.form-edit-wrapper.content-object-form {
  .form-edit-content {

    .tab-content {
      flex: 1 auto;
      overflow: hidden;
      &.content-object-forms-wrapper {
        padding: 0;
      }
      > div {
        overflow: hidden;
      }
    }
  }
}
.sync-notify{
  margin-top: -1.8rem;
  p {
    text-align: center;
    font-size: 1.6rem;
    padding: .5rem;
    margin: 0;
  }
}
.form-with-tabs{
  &__language-filter{
    min-width: 15rem;
    margin-right: 2rem;
  }
  &__wrap{
    padding: 0 !important;
    display: flex;
  }
  &__content{
    padding: 1.6rem;
    flex-grow: 1;
   // height: 100%;
    &.hide{
      display: none;
    }
  }
  .MuiTab-wrapped{
    max-width: unset;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .form-edit-wrapper {
    padding: 0;
    .form-edit-head {
      padding: 1rem;
      display: block;
      .title {
        font-size: 1.8rem;
        max-width: 100%;
      }
      .form-edit-controls {
        padding: 0.5rem 0 0;
        .right-side {
          display: flex;
          flex-grow: 1;
        }
      }
    }
    .form-edit-content {
      border-left: 0;
      border-right: 0;
      >div {
        >div {
          position: static !important;
        }
      }
    }
    .form-edit-controls {
      padding: 1rem;
    }
  }
  .form-edit-controls {
    .MuiButton-root {
      line-height: 1.2;
      font-size: 1rem;
      height: 2.8rem;
      flex-grow: 1;
      min-width: inherit;
    }
    .right-side {
      margin: -0.5rem -0.5rem 0;
      display: flex;
      button {
        margin: 0.5rem 0.5rem 0;

      }
    }
  }
}


