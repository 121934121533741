@import "src/styles/variabels";
.header {
  position: sticky;
  top: 0;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  background: $color_secondary;
  z-index: 88;
  height: 5rem;
}
.logo{
  height: 60%;
  img{
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
}
.aside {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  min-width: $drawer-width-open;
}
.main {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
}
.mainRight {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}
.mainLight{
  background-color: $color-secondary;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  :global {
    .user-nav {
      &.hide-avatar {
        .user-button {
          padding: 1.6rem 0 1.7rem;
        }
      }
    }
  }
}
