$color-primary: #5FB800;
$color-secondary: #36587A;
$color-secondary-light: rgba($color-secondary, 0.05);

$color-default: #434343;
$color-border: #EAEAEA;
$color-table: #888D8F;


$drawer-width-open: 25.6rem;
$drawer-width-close: 6.4rem;

$breakpoints: (
        xs : 0,
        sm : 576,
        md : 768,
        lg : 992,
        xl : 1200,
);

:export {
  @each $key, $value in $breakpoints {
    #{unquote($key)}: $value;
  }
}

:export {
  colorPrimary: $color-primary;
  colorSecondary: $color-secondary;
  colorSecondaryLight: $color-secondary-light;
  colorDefault: $color-default;
  colorBorder: $color-border;
  colorTable: $color-table;

  drawerWidthOpen: $drawer-width-open;
  drawerWidthClose: $drawer-width-close;
}
